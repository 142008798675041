/**
 * Created by slava on 17/9/19
 */

var MakeChoiceWindow = CleverappsWindow.extend({
    onWindowLoaded: function (component) {
        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

        this.component = component;
        this.isFirstChoice = component.choice === undefined;
        this.component.animateHover();
        this.choice = undefined;

        var styles = cleverapps.clone(cleverapps.styles.MakeChoiceWindow, true);

        if (!HomeController.needHighlight(component.unit)) {
            styles.shadow = false;
        }

        this._super({
            styles: styles,
            name: "makechoicewindow",
            content: this.getContent(),
            closeButton: !this.isFirstChoice,
            bandButton: !this.isFirstChoice
        });
    },

    getContentPadding: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();
        var padding = { top: sceneSize.height * 0.57 };
        return cc.paddingAddPadding(cc.padding(padding), this.getBackgroundPadding());
    },

    getContent: function () {
        var styles = cleverapps.styles.MakeChoiceWindow;

        this.items = [];
        for (var i = 0; i < this.component.choices.length; i++) {
            this.items.push(new MakeChoiceWindowItem({
                choice: i,
                isPreviousChoice: this.component.choice === i,
                onClick: this.selectChoice.bind(this, i, this.component instanceof RegionControl),
                switchable: this.component,
                params: this.component.choices[i]
            }));
        }

        var choicesLine = new cleverapps.Layout(this.items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.choicesLine.margin
        });

        var bg = cleverapps.UI.createScale9Sprite(bundles.make_choice_window.frames.window_bg_png);
        bg.setContentSize2(choicesLine.width + 2 * styles.bg.padding, styles.bg.height);
        choicesLine.setPositionRound(styles.choicesLine.position);
        bg.addChild(choicesLine, 1);

        this.selectedTitle = cleverapps.UI.generateOnlyText("MakeChoiceWindow.title", cleverapps.styles.FONTS.MAKE_CHOICE_WINDOW_TITLE);
        this.selectedTitle.setPositionRound(styles.choicesLine.title.position);
        this.selectedTitle.originalFontSize = this.selectedTitle.getFontSize();
        this.selectedTitle.fitTo(bg.width);
        bg.addChild(this.selectedTitle);

        var selectButton = this.selectButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.make_choice_window.frames.checkmark_png),
            type: cleverapps.styles.UI.Button.Images.small_button_green,
            width: styles.selectButton.width,
            height: styles.selectButton.height,
            onClicked: this.finish.bind(this)
        });

        selectButton.setPositionRound(styles.selectButton.position);
        bg.addChild(selectButton);

        if (this.isFirstChoice) {
            this.selectChoice(0);
        } else {
            this.selectChoice(this.component.choice, true);
        }

        return bg;
    },

    selectChoice: function (choice, silent) {
        if (this.selected !== choice) {
            if (this.selected !== undefined) {
                this.items[this.selected].unSelect();
            }
            this.selected = choice;
            this.items[this.selected].select();
            
            this.component.showChoice(choice, silent);
        }
    },

    makeChoice: function (choice) {
        var eventPrefix = this.component.choice === undefined ? cleverapps.EVENTS.MAKE_CHOICE : cleverapps.EVENTS.REDO_CHOICE;
        var choiceEvent = eventPrefix + choice + this.component.name;
        cleverapps.eventLogger.logEvent(choiceEvent);

        this.closeAnimation();
        this.animatingChoice = true;

        this.component.setChoice(choice, function () {
            Map2d.currentMap.counter.setTimeout(function () {
                this.animatingChoice = false;
                this.close();
            }.bind(this), 500);
        }.bind(this));
        this.choice = choice;
    },

    finish: function () {
        if (this.choice) {
            return;
        }

        if (this.items[this.selected].ad) {
            cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.CHOICE, this.createListener(function () {
                this.makeChoice(this.selected);
            }.bind(this)));
        } else if (this.items[this.selected].price) {
            var price = this.items[this.selected].price;
            if (levels.user.spendHard(cleverapps.EVENTS.SPENT.HOMEFIX_CHOICE, price)) {
                this.makeChoice(this.selected);
            }
        } else {
            this.makeChoice(this.selected);
        }
    },

    onShow: function () {
        this._super();
        var highlighter = Map2d.currentMap.onDiscoverMapView().highlighter;
        if (highlighter) {
            highlighter.addUnit(this.component.unit);
        }
        this.items.forEach(function (item) {
            item.onShow();
        });
    },

    onHide: function () {
        this._super();
        var highlighter = Map2d.currentMap.onDiscoverMapView().highlighter;
        if (highlighter) {
            highlighter.removeUnit();
        }
    },

    onClose: function () {
        if (this.choice === undefined) {
            this.component.showChoice(undefined, true);
        }

        this.component.stopAnimateHover();
    },

    close: function () {
        if (this.animatingChoice) {
            return;
        }

        this._super();
    },

    listBundles: function () {
        return ["make_choice_window"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MAKE_CHOICE_WINDOW_TITLE: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY
    }
});

cleverapps.styles.MakeChoiceWindow = {
    windowShowUpAnimation: {
        name: "fromBelow",
        force: true
    },
    noBackground: true,

    bg: {
        padding: 45,
        height: 390
    },

    selectButton: {
        width: 265,
        height: 90,
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -29 }
        }
    },
    title: {
        x: { align: "center" },
        y: { align: "top", dy: -30 }
    },

    choicesLine: {
        margin: 37,
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 10 }
        },
        title: {
            position: {
                x: { align: "center" },
                y: { align: "top", dy: -15 }
            }
        }
    }
};
