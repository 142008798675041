/**
 * Created by evgeny on 17.06.2024
 */

var Families = {};
Families.FurnitureByName = {};
var FamiliesIds = {};

var getFurnitureByName = function (name) {
    return Families.FurnitureByName[name];
};

var setFurnitureByName = function () {
    var current = 0;
    var furniture;

    if (typeof cc === "undefined") {
        var fs = require("fs");
        while (fs.existsSync("res/locations/" + current + "/data.json")) {
            furniture = require("../../../../../wordsoup/res/locations/" + current + "/data.json").items;
            furniture.forEach(function (furniture) {
                Families.FurnitureByName[furniture.name] = furniture;
            });
            current++;
        }
    } else {
        while (bundles["location_" + current]) {
            furniture = bundles["location_" + 0].jsons.data_json.getJson().items;
            furniture.forEach(function (furniture) {
                Families.FurnitureByName[furniture.name] = furniture;
            });
            current++;
        }
    }
};

Families.initialize = function () {
    Families.initializeBase();
    Families.afterInitialize();
};

Families.initializeBase = function () {
    setFurnitureByName();

    Families.multiCellBody = FamiliesHelper.MultiCellUnitFamily({}, {
        expeditions: "all"
    });

    Families.garbage1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Garbage 1"),
        expeditions: ["0", "1", "2", "3"]
    });

    Families.chloepetit = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Chloe Petit"),
        expeditions: "all"
    });

    Families.cobweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cobweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 2"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.fronthallwall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Front Hall Wall"),
        region: "fence_hall",
        decoratorCode: "right",
        invisible: true,
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cobweb3 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 3"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.sidehallwall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Side Hall Wall"),
        region: "fence_hall",
        decoratorCode: "up",
        invisible: true,
        expeditions: ["0", "1", "2", "3"]
    });
    Families.mainhallfloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Main Hall Floor"),
        region: "fog0",
        decoratorCode: "mainhallfloor",
        invisible: true,
        expeditions: ["0", "1", "2", "3"]
    });
    Families.highart = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("High Art"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.portablemenu = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Portable Menu"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.centraltables = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Central Tables"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.beveragealley = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Beverage Alley"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.plank1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Plank 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.relaxationzone = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Relaxation Zone"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.wastepaper = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Waste Paper"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cozycarpet = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cozy Carpet"),
        squeezable: false,
        expeditions: ["0", "1", "2", "3"]
    });
    Families.boxofwonders = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Box of Wonders"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.clutter1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Clutter 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.foodmuseum = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Food Museum"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.plank2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Plank 2"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.sourceofjoy = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Source of Joy"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.platformofdreams = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Platform of Dreams"),
        expeditions: ["0", "1", "2", "3"],
        multicell: FamiliesHelper.Shape2x3
    });
    Families.paint = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Paint"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.extratables = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Extra Tables"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cozybooth = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cozy Booth"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.minisidelights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Mini Side Lights"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.minifrontlights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Mini Front Lights"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.flowers = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Flowers"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.kitchenentrance = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Entrance"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.plates = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Plates"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.standlamp = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Stand Lamp"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cobweb4 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 4"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.leftkitchenwall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Left Kitchen Wall"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cobweb5 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 5"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.rightkitchenwall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Right Kitchen Wall"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.kitchenfloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Floor"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.gourmetstation = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Gourmet Station"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.plank3 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Plank 3"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.magiccorner = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Magic Corner"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.kitchenclutter = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Clutter"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.arcticparadise = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Arctic Paradise"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.centerpiece = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Center Piece"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.yummyisland = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Yummy Island"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.kitchenlights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Lights"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.chefsmotherland = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Chef's Motherland"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.brokentablewear = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Broken Tablewear"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.kitchencarpet = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Carpet"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.sungate = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Sun Gate"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cookinguniversity = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cooking University"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.reinvigoratedcorner = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Reinvigorated Corner"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.storageweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Web 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.rightstoragewall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Right Storage Wall"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.storageweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Web 2"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.leftstoragewall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Left Storage Wall"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.storagegarbage = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Garbage"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.storagefloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Floor"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.colorfulvitamins = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Colorful Vitamins"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.coldzone = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cold Zone"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.storageunit = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Unit"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.provisioncenter = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Provision Center"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.lastfrontier = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Last Frontier"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.storagelights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Lights"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officeweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Web 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.leftofficewall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Left Office Wall"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officeweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Web 2"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.rightofficewall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Right Office Wall"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officefloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Floor"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.wisdomtracker = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wisdom Tracker"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officeplanks1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Planks 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.artificialday = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Artificial Day"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.smallmercies = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Small Mercies"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.softcomfort = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Soft Comfort"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officewaste = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Waste"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.natureslefteye = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Nature's Left Eye"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officeplanks2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Planks 2"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.naturesrighteye = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Nature's Right Eye"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officepaint = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Paint"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cookingdatabase = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cooking Database"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officecarpet = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Carpet"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.frontdesk = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Front Desk"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.officelights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Lights"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.inspirationstation = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Inspiration Station"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.indoorsecology = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Indoors Ecology"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.bathroomweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathroom Web 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.sinkofwonders = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Sink of Wonders"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.bathromgarbage = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathrom Garbage"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.privacyzone = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Privacy Zone"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.bathroomweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathroom Web 2"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.wonderfulview = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wonderful View"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.whitestone = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("White Stone"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cliffhanger = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cliff Hanger"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.dusterminator = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Dusterminator"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.cleanstorm = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Clean Storm"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.staffroomweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Web 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.fashiondeposit = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Fashion Deposit"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.staffroomweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Web 2"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.recreationalbench = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Recreational Bench"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.staffroomgarbage = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Garbage"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.wall1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wall 1"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.wall2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wall 2"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.wall3 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wall 3"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.floor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Floor"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.staffroomfloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Floor"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.wall4 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wall 4"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.bathroomlights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathroom Lights"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.bathroomcarpet = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathroom Carpet"),
        expeditions: ["0", "1", "2", "3"]
    });
    Families.staffroomlights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Lights"),
        completeLocation: 0,
        expeditions: ["0", "1", "2", "3"]
    });
};

Families.afterInitialize = function () {
    if (cleverapps.config.debugMode && typeof exports === "undefined") {
        var unknown = cleverapps.dataLoader.load(DataLoader.TYPES.UNKNOWN_UNIT_TEST) || {};
        if (unknown.code !== undefined && unknown.stage !== undefined) {
            delete Families[unknown.code].units[unknown.stage];
        } else if (unknown.code) {
            delete Families[unknown.code];
        }
    }

    Families.codes = Object.keys(Families).filter(function (code) {
        return typeof Families[code] === "object" && ["codes", "types"].indexOf(code) === -1;
    });

    Object.keys(Families).forEach(function (key) {
        var furniture = Families[key].furniture;
        if (furniture) {
            furniture.unitCode = key;
        }
    });

    Families.types = {};

    var allExpeditions = [];
    Families.codes.forEach(function (code) {
        var family = Families[code];
        family.code = code;
        family.id = FamiliesIds[code];
        family.expeditions = family.expeditions || ["main"];
        if (!Array.isArray(family.expeditions)) {
            family.expeditions = [family.expeditions];
        }

        if (family.extraBundles && !Array.isArray(family.extraBundles)) {
            family.extraBundles = [family.extraBundles];
        }

        if (family.code === family.images) {
            console.warn("remove " + code + " images");
            delete family.images;
        }

        family.expeditions.forEach(function (expedition) {
            if (expedition !== "all" && allExpeditions.indexOf(expedition) === -1) {
                allExpeditions.push(expedition);
            }
        });

        if (family.type) {
            Families.types[family.type] = true;
        }
    });

    Families.codes.forEach(function (code) {
        var family = Families[code];
        if (family.expeditions.indexOf("all") !== -1) {
            family.expeditions = allExpeditions;
        }
        if (family.extraBundles && family.extraBundles.indexOf("all") !== -1) {
            family.extraBundles = allExpeditions;
        }
    });

    if (typeof window === "undefined" || cleverapps.config.debugMode) {
        Families.codes.forEach(function (code) {
            if (/[^a-zA-Z0-9]/.test(code)) {
                throw "Invalid family code: " + code + ". The family code must match the regex [a-zA-Z0-9]";
            }
        });
    }

    var codesById = {};
    for (var code in FamiliesIds) {
        var id = FamiliesIds[code];
        codesById[id] = code;
    }

    Families.getCodeById = function (id) {
        return codesById[id];
    };

    Families.getIdByCode = function (code) {
        return FamiliesIds[code];
    };

    Families.listExpeditionCodes = function (expedition) {
        var codes = Families.codes.filter(function (code) {
            return Families[code].expeditions.indexOf(String(expedition)) !== -1;
        });
        return cleverapps.createSet(codes);
    };
};

if (typeof cc === "undefined") {
    module.exports = Families;
}