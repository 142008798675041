/**
 * Created by evgeny on 14.06.2024
 */

var FamiliesHelper = {
    ShapeCell: [
        { x: 0, y: 0 }
    ],

    calculateShape: function (shape) {
        if (!shape) {
            return;
        }

        var regex = /(\d+)x(\d+)\s?(up)?/;
        var match = shape.match(regex);

        if (!match) {
            return;
        }

        var x = Number(match[1]);
        var y = Number(match[2]);

        var hasUP = match[3] === "up";
        var signX = hasUP ? -1 : 1;
        var signY = hasUP ? 1 : -1;

        shape = [];

        for (var i = 0; i < Math.abs(x); i++) {
            for (var j = 0; j < Math.abs(y); j++) {
                shape.push({ x: i * signX, y: j * signY });
            }
        }

        return shape;
    },

    MultiCellUnitFamily: function (unit, options) {
        options = options || {};

        return {
            type: options.type,
            units: Array.isArray(unit) ? unit : cleverapps.arrayFill(options.total || 1, unit),
            expeditions: options.expeditions,
            images: options.images,
            oneViewForStages: options.oneViewForStages,
            locators: options.locators,
            extraBundles: options.extraBundles
        };
    },

    FurnitureFamily: function (options) {
        var furniture = options.furniture;
        var units = [];
        for (var i = 0; i < furniture.stages.length; i++) {
            units.push({
                homefixId: options.furniture.name,
                highlight: true,
                invisible: options.invisible,
                movable: false,
                squeezable: options.squeezable,
                multicell: this.calculateShape(furniture.multicell),
                throwaway: furniture.throwaway,
                homeTask: options.furniture.name,
                fixable: !furniture.throwaway,
                untouchable: furniture.untouchable || i === furniture.untouchableStage
            });
        }

        if (furniture.choices) {
            units.push({
                homefixId: options.furniture.name,
                highlight: true,
                movable: false,
                squeezable: options.squeezable,
                multicell: this.calculateShape(furniture.multicell),
                invisible: options.invisible,
                fixable: true,
                switchable: options.region ? undefined : furniture.choices,
                regionControl: options.region ? {
                    region: options.region,
                    decoratorCode: options.decoratorCode,
                    choices: furniture.choices
                } : undefined
            });
        }

        if (options.completeLocation) {
            units[units.length - 1].completeLocation = options.completeLocation;
        }

        return {
            type: "furniture",
            furniture: furniture,
            units: units,
            expeditions: options && options.expeditions
        };
    }
};

if (typeof cc === "undefined") {
    module.exports = FamiliesHelper;
}
