/**
 * Created by Andrey Popov on 5/22/23.
 */

var MakeChoiceWindowItem = cc.Sprite.extend({
    ctor: function (options) {
        this._super(bundles.make_choice_window.frames.item_bg_png);
        this.options = options;
        this.unitCode = this.options.switchable.unit.code;
        this.switchable = this.options.switchable;

        this.frameShine = new cleverapps.Spine(bundles.make_choice_window.jsons.item_frame_shine);
        this.frameShine.setPosition(this.width / 2, this.height / 2);
        this.frameShine.setAnimation(0, "animation", true);
        this.addChild(this.frameShine, -1);
        this.frameShine.setVisible(false);

        var unitView = this.createChoiceView(options.choice);
        unitView.setPositionRound({ align: "center" }, { align: "center" });
        this.addChild(unitView);

        this.selectLight = new cleverapps.Spine(bundles.make_choice_window.jsons.icon_light_json);
        this.selectLight.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.selectLight);

        this.frame = new cc.Sprite(bundles.make_choice_window.frames.item_frame);
        this.frame.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.frame);

        if (!options.isPreviousChoice && (options.params.ads || options.params.price)) {
            this.createBadge(options.params);
        }

        this.addHandlers();
    },

    addHandlers: function () {
        this.hoverHandler = cleverapps.UI.applyHover(this);
        this.clickHandler = cleverapps.UI.onClick(this, this.onClick.bind(this));
    },

    removeHandlers: function () {
        if (this.hoverHandler) {
            this.hoverHandler.remove();
            delete this.hoverHandler;
        }

        if (this.clickHandler) {
            this.clickHandler.remove();
            delete this.clickHandler;
        }
    },

    onClick: function (event) {
        this.options.onClick();
        this.selectLight.setPosition(this.convertToNodeSpace(event.getLocation()));
        this.selectLight.setAnimation(0, "animation", false);
    },

    onShow: function () {
        if (this.badge && !this.badge.isVisible()) {
            this.showBadge();
        }
    },

    select: function () {
        this.removeHandlers();
        this.frameShine.setVisible(true);
        this.frame.setSpriteFrame(bundles.make_choice_window.frames.item_frame_on);
    },

    unSelect: function () {
        this.addHandlers();
        this.frameShine.setVisible(false);
        this.frame.setSpriteFrame(bundles.make_choice_window.frames.item_frame);
    },

    createBadge: function (params) {
        var styles = cleverapps.styles.MakeChoiceWindowItem.badge;

        var badge = this.badge = new cc.Sprite(bundles.make_choice_window.frames.item_badge_png);
        badge.setAnchorPoint(1, 1);
        badge.setVisible(false);

        if (params.ad) { 
            this.ad = true;
            badge.text = new cc.Sprite(bundles.make_choice_window.frames.item_ads_png);
        } else if (params.price) {
            this.price = params.price;
            badge.text = new TextWithIcon("$$" + params.price, {
                font: cleverapps.styles.FONTS.BUTTON_BOOSTER,
                icons: {
                    "##": bundles.make_choice_window.frames.item_ads_png
                }
            });
        }
        badge.text.setVisible(false);

        badge.addChild(badge.text);
        badge.text.setPositionRound(styles.text);
        badge.text.setRotation(styles.text.rotation);

        this.addChild(badge);
        badge.setPositionRound(styles);
    },

    showBadge: function () {
        var badge = this.badge;
        badge.setOpacity(0);
        badge.setVisible(true);
        badge.setLocalZOrder(-1);
        badge.runAction(new cc.Sequence(
            new cc.DelayTime(this.ad ? 0.2 : 0.55),
            new cc.Show(),
            new cc.Spawn(
                new cc.FadeIn(0.1),
                new cc.MoveBy(0.1, -6, -40).easing(cc.easeIn(1))
            ),
            new cc.RotateBy(0.15, -20).easing(cc.easeInOut(1)),
            new cc.CallFunc(function () {
                badge.setLocalZOrder(1);
            }),
            new cc.Spawn(
                new cc.RotateBy(0.15, 25).easing(cc.easeInOut(1)),
                new cc.MoveBy(0.1, 6, 45).easing(cc.easeIn(1))
            ),
            new cc.Spawn(
                new cc.MoveBy(0.05, 0, -5),
                new cc.RotateBy(0.05, -5)
            ),

            new cc.CallFunc(function () {
                AnimationsLibrary.run(
                    "showUp", 
                    badge.text,
                    {
                        flash: true,
                        fallOffset: 0,
                        fallDuration: 0 
                    }
                );
            })

        ));
    },

    createChoiceView: function (choice) {
        var view = new cleverapps.Spine(SwitchableView.UnitIconJson(this.switchable.unit, choice));

        var styles = cleverapps.styles.MakeChoiceWindowItem.choiceView;
        cleverapps.UI.fitToBox(view, { width: this.width - styles.offsetX, height: this.height - styles.offsetY });
        view.setAnimation(0, "animation", true);

        return view;
    }
});

cleverapps.styles.MakeChoiceWindowItem = {
    badge: {
        x: { align: "right", dx: 26 },
        y: { align: "bottom", dy: -30 },
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 },
            width: 200,
            rotation: -15
        }
    },
    choiceView: {
        offsetX: 80,
        offsetY: 80
    }
};
