/**
 * Created by mac on 2/5/20
 */

var HomeController = function () {
    var map = this.map = Map2d.currentMap;
    var home = this.home = cleverapps.meta.getMainObject();

    var updateUnits = function () {
        home.saveUnits(map.getInfo().units);
    };

    var updateFogs = function () {
        home.saveFogs(map.getInfo().fogs);
    };

    map.on("saveUnit", updateUnits);
    map.on("removeUnit", updateUnits);
    map.on("saveFogs", updateFogs);

    if (!cleverapps.config.editorMode) {
        if (home.oldItems) {
            map.initFogs(["fog0"]);
            this.migrateOldItems(home.oldItems);
            delete home.oldItems;
        } else {
            map.initFogs(home.getInfo().fogs);
            map.load(home.getInfo().units);
        }
        map.fogs.initStage();
        cleverapps.config.saveUnitsEnabled = true;

        if (map.fogs.blocks.fog0) {
            map.fogs.blocks.fog0.open();
        }
        home.onUnitsLoaded();
    } else {
        Map2d.InsertDefaults(map, map.field);
    }

    map.counter.registerStage(1, map.fogs.calcFogStatesStage.bind(map.fogs));
    map.counter.registerStage(2, this.getNewTaskStage.bind(this));
    map.counter.registerStage(3, this.moveNextLocationStage.bind(this));
    
    this.changeStageHandler = home.on("change:stage", this.changeStage.bind(this));
    this.newTaskHandler = home.on("newTask", this.newTask.bind(this));

    this.handleMakeChoice = home.on("handleMakeChoice", this.handleMakeChoiceWindow.bind(this));
};

HomeController.prototype.destructor = function () {
    this.changeStageHandler.clear();
    this.newTaskHandler.clear();
    this.handleMakeChoice.clear();

    this.map.stop();
    this.map = undefined;
    this.home = undefined;
};

HomeController.prototype.migrateOldItems = function (oldItems) {
    Object.keys(oldItems).forEach(function (id) {
        var furniture = this.home.getFurnitureById(Number(id));
        var item = oldItems[id];

        if (Families.FurnitureByName[furniture.name].throwaway) {
            return;
        }

        var fakeUnit = Map2d.currentMap.fogs.findFakeUnit({
            code: Families.FurnitureByName[furniture.name].unitCode,
            type: "furniture",
            stage: 0
        });
        if (fakeUnit) {
            fakeUnit.stage = item.choice !== undefined ? item.stage : item.stage - 1;
            fakeUnit.choice = item.choice;
            this.map.loadUnit(fakeUnit.x, fakeUnit.y, fakeUnit);
        }
    }.bind(this));
};

HomeController.prototype.getNewTaskStage = function () {
    if (!cleverapps.config.saveUnitsEnabled) {
        return;
    }

    var toOpen = this.home.getFurnitureToOpen();

    if (!toOpen.length) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "homefixNewTask",
        actions: toOpen.map(function (furniture) {
            return function (f) {
                this.home.giveNewTask(f, furniture);
            }.bind(this);
        }.bind(this))
    });
};

HomeController.prototype.moveNextLocationStage = function () {
    if (!this.home.checkCompleted() || !this.home.isCurrentLocation()) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "homeMoveNextLocation",
        actions: [
            function (f) {
                cleverapps.meta.moveNextLocation();
                f();
            },
            function (f) {
                if (cleverapps.meta.isCompleted()) {
                    new AllDoneWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                    return;
                }

                new TravelMapWindow({ moveNext: true });
                cleverapps.focusManager.onceNoWindowsListener = f;
            },
            function (f) {
                if (cleverapps.meta.isCompleted()) {
                    f();
                    return;
                }
                cleverapps.scenes.replaceScene(new HomefixScene(), f);
            }
        ]
    });
};

HomeController.prototype.changeStage = function (furniture, f) {
    var actions = [
        function (f) {
            this.showUnit(furniture.unit, f);
        }.bind(this),

        function (f) {
            this.moveNextUnitStage(furniture.unit, f);
        }.bind(this),

        function (f) {
            if (this.shownUnit && this.shownUnit !== furniture.unit) {
                this.showUnit(furniture.unit, f, true);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            var component = furniture.getChoiceComponent();
            if (component) {
                new MakeChoiceWindow(component);
                cleverapps.focusManager.onceNoWindowsListener = function () {
                    f();
                };
            } else {
                f();
            }
        },

        function (f) {
            if (this.home.checkCompleted()) {
                this.home.onComplete(f);
            } else {
                f();
            }
        }.bind(this)
    ];

    if (!furniture.isComplete()) {
        actions = actions.concat([
            function (f) {
                this.newTask(furniture, f);
            }.bind(this)
        ]);
    } else {
        actions = actions.concat([
            function (f) {
                this.unShowUnit();
                f();
            }.bind(this)]);
    }

    cleverapps.focusManager.compound(f, actions);
};

HomeController.prototype.moveNextUnitStage = function (unit, f) {
    if (!unit) {
        f();
        return;
    }

    Map2d.currentMap.fogs.wantsCalcFogStates = true;

    var throwaway = unit.findComponent(Throwaway);

    if (throwaway) {
        delete this.shownUnit;
        throwaway.animateDestroy(f);
        return;
    }

    var fixable = unit.findComponent(Fixable);

    if (fixable) {
        fixable.updateStage(f);
    }
};

HomeController.prototype.newTask = function (furniture, f) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            this.showUnit(furniture.unit, f);
        }.bind(this),

        function (f) {
            this.showStageDialogue(furniture, f);
        }.bind(this),

        function (f) {
            this.home.animateNewTask(furniture, f);
        }.bind(this),

        function (f) {
            this.unShowUnit();
            f();
        }.bind(this)
    ]);
};

HomeController.prototype.showUnit = function (unit, callback, silent) {
    if (!unit) {
        callback();
        return;
    }

    this.shownUnit = unit;

    new ActionPlayer([
        function (f) {
            var params = this.getShowUnitParams();
            if (silent) {
                params.duration = 0;
            }
            params.callback = f;
            Map2d.currentMap.zoomIn(unit, params);
        }.bind(this),

        function (f) {
            if (HomeController.needHighlight(unit)) {
                var highlighter = Map2d.currentMap.onDiscoverMapView().highlighter;
                if (highlighter) {
                    highlighter.addUnit(unit);
                    f();
                } else {
                    Map2d.currentMap.highlightUnit(unit, f, { spotlight: true, ground: false });
                }
            } else {
                f();
            }
        }
    ]).play(callback);
};

HomeController.prototype.onResize = function () {
    if (!this.shownUnit) {
        return;
    }
    this.showUnit(this.shownUnit, function () {}, true);
};

HomeController.prototype.getShowUnitParams = function () {
    return {
        zoom: 2,
        padding: {
            top: 60,
            bottom: cleverapps.resolution.getSceneSize().height * 0.4,
            right: 60,
            left: 60
        },
        centerOffset: {
            x: 0,
            y: cleverapps.resolution.getSceneSize().height / 8
        }
    };
};

HomeController.prototype.unShowUnit = function (withZoomOut) {
    delete this.shownUnit;
    Map2d.currentMap.unhighlightUnit();
    if (withZoomOut) {
        Map2d.currentMap.zoomOut();
    } else {
        Map2d.currentMap.cancelZoomOut();
    }
};

HomeController.prototype.showUp = function (f) {
    if (!this.home.isCurrentLocation()) {
        f();
        return;
    }
    cleverapps.focusManager.compound(f, [
        function (f) {
            setTimeout(f, 500);
        },

        function (f) {
            cleverapps.restoreProgress.update();
            f();
        },

        function (f) {
            var furniture = this.home.getMaxFurniture();
            if (furniture.id === this.home.firstFurnitureId()) {
                if (furniture.canBeUpgraded()) {
                    this.home.moveNext(furniture, f);
                } else if (!this.home.forcedAlready) {
                    this.home.forcedAlready = true;
                    cleverapps.focusManager.compound(f, [
                        function (f) {
                            this.showUnit(furniture.unit, f);
                        }.bind(this),

                        function (f) {
                            this.showStageDialogue(furniture, f);
                        }.bind(this),

                        function (f) {
                            cleverapps.meta.wantsToPlay(f);
                        }
                    ]);
                } else {
                    f();
                }
            } else if (this.home.getFurnitureToUpgrade()) {
                new HomeTasksWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            } else {
                f();
            }
        }.bind(this)
    ]);
};

HomeController.prototype.showStageDialogue = function (furniture, f) {
    var furnitureInfo = furniture.getInfo();

    if (!furnitureInfo) {
        cleverapps.throwAsync("no furniture info");
        f();
        return;
    }

    var dialogue = this.home.createDialogue(furnitureInfo.dialogue);
    if (!dialogue) {
        f();
        return;
    }

    if (furniture.unit && furniture.unit.onGetView && furniture.unit.onGetView()) {
        dialogue.on("showUp", function () {
            var highlighter = new UnitHighlighter(Map2d.currentMap.getMapView(), furniture.unit, { spotlight: true, ground: false });
            dialogue.on("beforeClose", highlighter.unhighlight.bind(highlighter));
        });
    }

    dialogue.on("afterClose", f);

    new DialogueView(dialogue);
};

HomeController.prototype.handleMakeChoiceWindow = function (component) {
    cleverapps.focusManager.display({
        focus: "MakeChoiceWindow",
        actions: [
            function (f) {
                this.showUnit(component.unit, f);
            }.bind(this),
            function (f) {
                new MakeChoiceWindow(component);
                cleverapps.focusManager.onceNoWindowsListener = f;
            },
            function (f) {
                var wasChanged = !component.unit.onGetView();
                this.unShowUnit(!wasChanged);
                f();
            }.bind(this)
        ]
    });
};

HomeController.needHighlight = function (unit) {
    if (unit.findComponent(Invisible)) {
        return false;
    }

    var multiCell = unit.findComponent(MultiCell);
    if (multiCell) {
        var size = multiCell.getSize();
        if (size.width > 2 && size.height > 2) {
            return false;
        }
    }

    return true;
};
